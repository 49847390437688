import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Contact_Us_Seo from '../../SEO/Contact_Us_Seo';
import Swal from 'sweetalert2';

function ContactUs() {
    const [formData, setFormData] = useState({ name: '', email: '', phoneno: '', msg: '' });
    const [errors, setErrors] = useState({});
    const [reCaptcha, setCaptchaToken] = useState('');

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) {
            formErrors.name = 'Name is required';
        } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
            formErrors.name = 'Name can only contain letters and spaces';
        }
        if (!formData.email) {
            formErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = 'Email address is invalid';
        }
        if (!formData.phoneno) {
            formErrors.phoneno = 'Phone number is required';
        } else if (!/^\d{10,15}$/.test(formData.phoneno)) {
            formErrors.phoneno = 'Phone number is invalid';
        }
        if (!formData.msg) {
            formErrors.msg = 'Message is required';
        }
        if (!reCaptcha) {
            formErrors.captcha = 'Captcha is required';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.grecaptcha.reset();
        if (validateForm()) {
            try {
                const response = await axios.post('https://riyoremit.com/prod/api/contactus/savecontactdetails', { ...formData, reCaptcha });
                const { responseCode, data } = response.data;

                if (responseCode === "00") {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Form submitted successfully!',
                        confirmButtonText: 'OK'
                    });
                    setFormData({ name: '', email: '', phoneno: '', msg: '' });
                    setCaptchaToken('');
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: data || 'There was an issue submitting the form. Please try again later.',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'There was an issue submitting the form. Please try again later.',
                    confirmButtonText: 'OK'
                });
                console.error('Error submitting form:', error);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    // Check if all required fields are filled
    const isFormValid = () => {
        return formData.name && formData.email && formData.phoneno && reCaptcha;
    };

    return (
        <>
            <Contact_Us_Seo />
            <Header />
            <main className="page_content">
                <section className="page_header text-center bg-dark section_decoration overflow-hidden">
                    <div className="container">
                        <h1 className="page_title text-white">Contact Us</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/">Home</NavLink></li>
                            <li className="active"><NavLink to="/contact">Contact Us</NavLink></li>
                        </ul>
                    </div>
                </section>
                <section className="contact_section section_space bg-light">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="comment_form p-lg-5">
                                    <div className="heading_block">
                                        <h2 className="heading_text">Contact Us</h2>
                                        <p className="heading_description mb-0">If you need information or support, feel free to contact us.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="input_name"
                                                        placeholder="Enter Name*"
                                                        value={formData.name}
                                                        onChange={handleInputChange}
                                                        required
                                                        className="form-control" />
                                                    {errors.name && <span className="error">{errors.name}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        id="input_email"
                                                        placeholder="Enter Email ID*"
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                        required
                                                        className="form-control" />
                                                    {errors.email && <span className="error">{errors.email}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-3">
                                                    <input
                                                        type="text"
                                                        name="phoneno"
                                                        id="input_phone"
                                                        placeholder="Enter Phone Number*"
                                                        value={formData.phoneno}
                                                        onChange={handleInputChange}
                                                        required
                                                        className="form-control" />
                                                    {errors.phoneno && <span className="error">{errors.phoneno}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group mb-3">
                                                    <textarea
                                                        placeholder="Your Message"
                                                        name="msg"
                                                        value={formData.msg}
                                                        onChange={handleInputChange}
                                                        required
                                                        id="message" cols={5} rows={1} className="form-control" defaultValue={""} />
                                                    {errors.msg && <span className="error">{errors.msg}</span>}
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <ReCAPTCHA
                                                            sitekey="6Le893QqAAAAADDTV8SZu7oQKjdhfwlxshy2oNkV"
                                                            onChange={handleCaptchaChange}
                                                        />
                                                        {errors.captcha && <span className="error">{errors.captcha}</span>}
                                                    </div>
                                                </div>
                                                <div className='col-6 justify-content-center text-center'>
                                                    <button className="btn text-dark" type="submit" disabled={!isFormValid()}>
                                                        <span className="btn_label">Submit</span>
                                                        <span className="btn_icon ml-10">
                                                            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7H0V9Z" fill="#012A2B" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="contact_info_box p-5">
                                    <h3 className="heading_text">Contact Info</h3>
                                    <ul className="iconlist_block unordered_list_block">
                                        <li>
                                            <NavLink to="#">
                                                <span className="iconlist_icon">
                                                    <img src="assets/images/icons/icon-location.svg" alt="Icon Location" style={{ width: '30px' }} />
                                                </span>
                                                <span className="iconlist_text">12-22 Newhall Street, Birmingham, England, B3 3AS</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="tel:441217535521">
                                                <span className="iconlist_icon">
                                                    <img src="assets/images/icons/icon_calling_3.svg" alt="Icon Calling" />
                                                </span>
                                                <span className="iconlist_text">+(44) 121 753 5521</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="mailto:info@riyoremit.com">
                                                <span className="iconlist_icon">
                                                    <img src="assets/images/icons/icon_email_3.svg" alt="Icon Email" />
                                                </span>
                                                <span className="iconlist_text">info@riyoremit.com</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <ul className="social_icons_block unordered_list mb-0">
                                        <li>
                                            <NavLink aria-label="Twitter X" to="#!">
                                                <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.92704 6.35148L14.5111 0H13.1879L8.33921 5.5149L4.4666 0H0L5.85615 8.3395L0 15H1.32333L6.44364 9.17608L10.5334 15H15L8.92671 6.35148H8.92704ZM7.11456 8.41297L6.52121 7.58255L1.80014 0.974755H3.83269L7.64265 6.30746L8.236 7.13788L13.1885 14.0696H11.156L7.11456 8.41329V8.41297Z" />
                                                </svg>
                                            </NavLink>
                                        </li>
                                        <li><NavLink aria-label="Facebook" to="#!"><i className="fa-brands fa-facebook-f" /></NavLink></li>
                                        <li><NavLink aria-label="Linkedin" to="#!"><i className="fa-brands fa-linkedin-in" /></NavLink></li>
                                    </ul>
                                    <hr />
                                    <ul className="iconlist_block unordered_list_block mb-0">
                                        <li>
                                            <span className="iconlist_text">
                                                <strong className="text-dark d-block">Our Office Open Time:</strong> Mon - Sat : 9.00am-6.00pm <mark className="d-block text-danger">Sunday : Closed</mark>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default ContactUs;
